import React, { useEffect, useState } from 'react';
import './component.css';
import ProgressBarIndicator from './ProgressBarIndicator';
const ProgressBar = ({ data, bolum_adi }) => {
    const [progressIN, setProgressIN] = useState(0);
    const [progressOUT, setProgressOUT] = useState(0);
    const [progressPIPE, setProgressPIPE] = useState(0);
    const [progressHum, setProgressHum] = useState(0);
    const [progressPress, setProgressPress] = useState(0);
    const [progressEfficiency, setProgressEfficiency] = useState(0);
    
    // Verinin ne zaman alındığını takip etmek için zamanlayıcı
    const [lastDataTime, setLastDataTime] = useState(Date.now());
    
    // Offline olma durumu
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        if (data) {
            // Eğer data gelirse zamanlayıcıyı sıfırla
            setLastDataTime(Date.now());

            // İç Sıcaklık (tmpIN): 0°C - 40°C
            const progressINValue = Math.min(Math.max(((parseFloat(data.tmpIN) - (-30)) / (40 - (-30)) ) * 100, 0), 100);
            setProgressIN(progressINValue);

            // Dış Sıcaklık (tmpOUT): -30°C - 50°C
            const progressOUTValue = Math.min(Math.max(((parseFloat(data.tmpOUT) - (-30)) / (50 - (-30))) * 100, 0), 100);
            setProgressOUT(progressOUTValue);

            // Boru Sıcaklığı (tmpPIPE): 0°C - 90°C
            const progressPIPEValue = Math.min(Math.max(((parseFloat(data.tmpPIPE) - 0) / (90 - 0)) * 100, 0), 100);
            setProgressPIPE(progressPIPEValue);

            // Nem (hum): 0% - 100%
            const progressHumValue = Math.min(Math.max(parseFloat(data.hum), 0), 100);
            setProgressHum(progressHumValue);

            // Basınç (press): 960 hPa - 1040 hPa
            const progressPressValue = Math.min(Math.max(((parseInt(data.press, 10) - 960) / (1040 - 960)) * 100, 0), 100);
            setProgressPress(progressPressValue);

            // Enerji verimliliği hesaplama
            const efficiency = calculateEfficiency(data).toFixed(2);
            setProgressEfficiency(efficiency);
            
        }
    }, [data]);

    useEffect(() => {
        // 3 saniye (3000 ms) içerisinde veri gelmezse offline olarak işaretle
        const interval = setInterval(() => {
            if (Date.now() - lastDataTime > 30000) {
                setIsOffline(true);
            }else setIsOffline(false);
        }, 1000); // Her saniye kontrol et

        // Temizlik işlemi
        return () => clearInterval(interval);
    }, [lastDataTime]);

    // Enerji verimliliği hesaplama fonksiyonu
    const calculateEfficiency = (data) => {
        const tempDiff = Math.abs(parseFloat(data.tmpIN) - parseFloat(data.tmpOUT)); // İç ve dış sıcaklık farkı
        const tempWeight = 0.4;
        const humWeight = 0.3;
        const pressWeight = 0.3;

        const tempScore = Math.max(0, 100 - tempDiff);
        const humScore = (parseFloat(data.hum) >= 30 && parseFloat(data.hum) <= 60) ? 100 : 50;
        const pressScore = (parseInt(data.press, 10) >= 980 && parseInt(data.press, 10) <= 1020) ? 100 : 50;

        const efficiency = Math.min(
            Math.max(
                (tempScore * tempWeight) + (humScore * humWeight) + (pressScore * pressWeight),
                0
            ),
            100
        );

        return efficiency;
    };

    return (
        isOffline ? (
            
            <div className="row progress-container border-bottom ">
                <div className="col s12 m12 l12  padding-small small center">
                    <strong>{bolum_adi}</strong>: 
                    Cihaz bağlı değil...
                </div>
            </div>

        ) : (
            <div className="col theme-l1 s12 m6 l4 progress-container border-bottom ">
                <div className='row center' >{bolum_adi}</div>
                <ProgressBarIndicator title="iç Mekan" isOffline={isOffline} symbol="°C" dataValue={data.tmpIN} progressValue={progressIN} minLimit={50} maxLimit={80} />
                <ProgressBarIndicator title="Dıiş Mekân"  isOffline={isOffline} symbol="°C" dataValue={data.tmpOUT} progressValue={progressOUT} minLimit={50} maxLimit={80} />
                <ProgressBarIndicator title="Kalorifer"  isOffline={isOffline} symbol="°C" dataValue={data.tmpPIPE} progressValue={progressPIPE} minLimit={50} maxLimit={80} />
                <ProgressBarIndicator title="Nem"  isOffline={isOffline} symbol="%" dataValue={data.hum} progressValue={progressHum} minLimit={50} maxLimit={80} />
                <ProgressBarIndicator title="Işık"  isOffline={isOffline} symbol="%" dataValue={data.hum} progressValue={progressHum} minLimit={50} maxLimit={80} />
                <ProgressBarIndicator title="Verimlilik"  isOffline={isOffline} symbol=" %" dataValue={progressEfficiency} progressValue={progressEfficiency} minLimit={50} maxLimit={80} />
            </div>
        )
    );
    
};

export default ProgressBar;
