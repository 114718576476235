import HikayeProgressBar from "./HikayeProgressBar";
import Isimlik from "./Isimlik";
import Cumle from "./Cumle";
function MainHikaye() {
    return ( 
    <div className="container">
        
        <div className="row center"> <h2>Hikâye yazma oyunu</h2> </div>
       
        <h4>Katılımcılar</h4>
        
        <Isimlik isim="Osman C." tip="uye"/>
        <Isimlik isim="Ayşe R." tip="uye"/>
        <Isimlik isim="Ali B." tip="uye"/>
        <Isimlik isim="Hakan D." tip="mod"/>
         <br/>
       
        
        <div className="row">
            <h2>Erik Mevsimi:</h2>
            
            <Cumle isim="Osman C." tip="uye" time="20" text="Erik ağaçları çiçek açmıştı.Her taraf rengârenkti."></Cumle>
            <Cumle isim="Ayşe R." tip="uye" time="10" text="Ahmet, sabah erkenden bahçeye koştu. Çiçeklerin arasında gezinirken derin bir nefes aldı ve gülümsedi. “Anne! Bahar gerçekten gelmiş!” diye bağırdı."></Cumle>
            <Cumle isim="Ali B." tip="uye" time="29" text="Annesi gülerek pencereden seslendi, “O halde bahçeyi biraz sulamaya ne dersin, Ahmet?” Ahmet, kovasını alıp kuyunun başına geçti. O sırada yan komşunun kızı Selma da elinde bir sepetle bahçeye çıktı. Ahmet, “Selma! Gel, birlikte çiçeklere su verelim!” diye seslendi."></Cumle>
            <Cumle isim="Hakan D." tip="mod" time="5" text="Annesi gülerek pencereden seslendi, “O halde bahçeyi biraz sulamaya ne dersin, Ahmet?” Ahmet, kovasını alıp kuyunun başına geçti. O sırada yan komşunun kızı Selma da elinde bir sepetle bahçeye çıktı. Ahmet, “Selma! Gel, birlikte çiçeklere su verelim!” diye seslendi."></Cumle>
            <Cumle isim="Hakan D." tip="mod" time="0" text="Annesi gülerek pencereden seslendi, “O halde bahçeyi biraz sulamaya ne dersin, Ahmet?” Ahmet, kovasını alıp kuyunun başına geçti. O sırada yan komşunun kızı Selma da elinde bir sepetle bahçeye çıktı. Ahmet, “Selma! Gel, birlikte çiçeklere su verelim!” diye seslendi."></Cumle>
            <Cumle isim="Hakan D." tip="mod" time="30" text="Annesi gülerek pencereden seslendi, “O halde bahçeyi biraz sulamaya ne dersin, Ahmet?” Ahmet, kovasını alıp kuyunun başına geçti. O sırada yan komşunun kızı Selma da elinde bir sepetle bahçeye çıktı. Ahmet, “Selma! Gel, birlikte çiçeklere su verelim!” diye seslendi."></Cumle>
 
        </div>

        <div className="row padding-small card theme-l3 round">
            <div className="row center">
                <h4>Hikâye için cümlenizi buraya yazınız</h4>
            </div>
            <div className="row">
                <textarea name="metin" id="metin" rows="4" className="large block theme-l2 input"></textarea>
            </div>
            <div className="row">
                <div className="col s6 m6 l6 padding">
                    <input type="button" value="Temizle" className="round block red border-red text-black large"/>
                </div>
                <div className="col s6 m6 l6 padding">
                    <input type="button" value="Ekle" className="round block green border-green large"/> 
                </div>
            </div>
        </div>
    </div> 
);
}

export default MainHikaye;