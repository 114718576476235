//projeThumb.jsx:
function ProjeThumb({title,prototip,rapor,image,link,className}) {
    return ( 
        <div className="col padding-small col s6 m4 l2 ">
            <a href={link}> 
                <div className={`row ${className} center border padding-small round `} style={{ minHeight: "350px" }}>
                    <img src={image} alt={title} className="image"/> 
                    <h4>{title}</h4> 
                    <h6>Prototip:%{prototip} </h6>
                    <h6>Rapor:%{rapor} </h6>
                </div>
             </a>  
        </div>
     );
}

export default ProjeThumb;