import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import ProgressBar from './progressBar';

const BinaDetay = () => {
    const { bina_id } = useParams(); // Parametre olarak gelen bina_id'yi alıyoruz
    const [bolumler, setBolumler] = useState([]); // API'den alınan bina bilgilerini tutacak
    const [wsMessages, setWsMessages] = useState([]); // WebSocket verilerini depolamak için
    const [socket, setSocket] = useState(null);

    // WebSocket bağlantısını başlatan ve yeniden bağlanmayı yöneten fonksiyon
    const connectWebSocket = () => {
        const newSocket = new WebSocket('wss://haysaf.com/api'); // WebSocket bağlantısı

        newSocket.onopen = () => {
            //console.log("ws connected");
        };

        newSocket.onmessage = (event) => {
            const msgJSON = JSON.parse(event.data); // WebSocket verisini alıyoruz

            // Gelen veriyi sadece bina_id'ye uygun olanları ekleyeceğiz
            setWsMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                const deviceIndex = updatedMessages.findIndex(item => item.deviceID === msgJSON.deviceID);

                if (deviceIndex >= 0) {
                    // Aynı deviceID varsa, veriyi güncelle
                    updatedMessages[deviceIndex] = { ...updatedMessages[deviceIndex], ...msgJSON };
                } else {
                    // Yeni bir deviceID varsa, ekle
                    updatedMessages.push(msgJSON);
                }

                return updatedMessages;
            });
        };

        newSocket.onclose = () => {
            //console.log("ws disconnected. Reconnecting...");
            setTimeout(connectWebSocket, 1000); // Bağlantı koparsa 1 saniye sonra yeniden bağlan
        };

        setSocket(newSocket);
    };

    // WebSocket'i başlat
    useEffect(() => {
        connectWebSocket();
        return () => {
            if (socket) {
                socket.close();  // component unmount olduğunda WebSocket'i kapat
            }
        };
    }, []); // sadece component mount olduğunda çalışır

    // API'den bina verilerini alıyoruz
    useEffect(() => {
        const bolumGetir = async () => {
            try {
                const params = `https://haysaf.com/get/Binalar/${bina_id}`;
                const result = await axios.get(params);
                setBolumler(result.data); // API'den gelen bina bilgilerini state'e kaydediyoruz
                // //console.log(result.data); // Veriyi konsola yazdır
            } catch (error) {
                //console.log(error);
            }
        };
        if (bina_id) bolumGetir(); // sadece bina_id varsa, API isteği yapılır
    }, [bina_id]);

    // WebSocket verilerini sadece `deviceID`'si API verilerindeki ile eşleşenleri filtrele
    const filteredMessages = wsMessages.filter(msg => 
        bolumler.some(bolum => bolum.deviceID === msg.deviceID) // WebSocket verisinin `deviceID`'sinin API verilerindeki ile eşleşmesi
    );

    // Bina ID'ye ait cihazların verilerini render et
    return (
        <div className='theme-l1'>
            <h4 className="center">Sibop Bilim Ve Sanat Merkezi Bina Sensör Verileri</h4>
            {
                bolumler && bolumler.length > 0 ? (
                    <div className='row padding-small theme-l1'>
                        {bolumler.map((element) => {
                            // WebSocket verisi mevcutsa ve deviceID eşleşiyorsa
                            const wsData = filteredMessages.find(msg => msg.deviceID === element.deviceID);

                            // Eğer WebSocket'ten gelen veri varsa, ProgressBar bileşenine onu geçiriyoruz
                            return (
                                <div className="theme-l1" key={element.deviceID}>
                                    <ProgressBar 
                                        id={element.deviceID} 
                                        bolum_adi={element.bolum_adi}
                                        data={wsData || element} // WebSocket'ten gelen veriyi kullan, yoksa API verisini kullan
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p> Veri Geliyor...</p>
                )
            }
        </div>
    );
};

export default BinaDetay;
