import Isimlik from "./Isimlik";
function Cumle({text,isim,tip,time}) {
    return ( 
        <div className="row padding">
            
                <span className="wide">{text}</span>
         
            <div  className="right" style={{width:100}}>
                <div className= "text-blue small" >
                     <Isimlik isim={isim} tip={tip}/>
                     {time}sn
                        <div 
                        className={`round blue animate-left progress-bar`} 
                        style={{ 
                            width: `${time*100/30}%`,
                            height:4 
                        }} >
                        </div>
                </div>
            </div>
        </div>
     );
}

export default Cumle;