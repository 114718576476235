function ProgressBarIndicator({title,symbol,isOffline,dataValue,progressValue, minLimit,maxLimit}) {
    return (
        <div className="col s12 m6 l12">
            <div className="row">
                    <div className="col s3 m3 l2 ">
                        {title}
                    </div>
                    <div className="col s9 m9 l10 ">
                            {isOffline || !dataValue ? (
                            <div className=" animate-left progress-bar card offline">
                                <span className="progress-text">Veri Alınıyor...</span>
                            </div>
                            ) : (
                                <div 
                                className={`container animate-left border-bottom progress-bar ${progressValue >= maxLimit ? 'green' : progressValue >= minLimit ? 'yellow' : 'red'}`} 
                                style={{ 
                                    width: `${progressValue}%`, 
                                    wordWrap: 'normal', 
                                    whiteSpace: 'nowrap', 
                                    overflow: 'hidden', 
                                    textOverflow: 'ellipsis'
                                }}
                                >
                                <span className="progress-text">{dataValue} {symbol}</span>
                                </div>

                            )}
                    </div>
            </div>
        </div>
    );
}

export default ProgressBarIndicator;