function Isimlik({isim,tip}) {
    let renk="";
    if(tip==="uye") renk='tag small round green';
    if(tip==="mod") renk='tag small round red';
    return ( 
    <a className="padding-small">
        <span className={renk}>{isim}</span>
    </a> 
    
    );
}

export default Isimlik;